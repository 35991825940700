import {SortOrder, QueryV2} from '@wix/ambassador-crypto-nft-v1-digital-asset/build/es/types';
import settingsParams from '../components/Gallery/settingsParams';
import {SortingOptions, SortingOptionsIds} from '../types/galleryTypes';
import {convertToSortingOptions} from '../utils';

export class SortingService {
  public getSortOptions(settingsGetter: {get: Function} /* TBD - install ISettingsGetter type*/): SortingOptions[] {
    const options = [];

    if (settingsGetter.get(settingsParams.sortingOptionRecentlyAdded)) {
      options.push({
        id: SortingOptionsIds.createdDate,
        name: 'Recently Added',
      });
    }

    if (settingsGetter.get(settingsParams.sortingOptionPriceLowToHigh)) {
      options.push({
        id: SortingOptionsIds.priceLowToHigh,
        name: 'Price: Low to High',
      });
    }

    if (settingsGetter.get(settingsParams.sortingOptionPriceHighToLow)) {
      options.push({
        id: SortingOptionsIds.priceHighToLow,
        name: 'Price: High to Low',
      });
    }

    return convertToSortingOptions(options);
  }

  public getSortParameterById(sortId: SortingOptionsIds): QueryV2['sort'] {
    switch (sortId) {
      case SortingOptionsIds.createdDate:
        return [{fieldName: 'createdDate', order: SortOrder.DESC}];
      case SortingOptionsIds.priceHighToLow:
        return [{fieldName: 'priceData.price', order: SortOrder.DESC}];
      case SortingOptionsIds.priceLowToHigh:
        return [{fieldName: 'priceData.price', order: SortOrder.ASC}];
    }
  }
}
