import {CreateControllerFn} from '@wix/yoshi-flow-editor';
import {GalleryStore} from './Widget/store/GalleryStore';

const createController: CreateControllerFn = async ({controllerConfig, flowAPI: {settings, httpClient}}) => {
  let galleryStore: GalleryStore;
  const {
    platformAPIs: {storage, bi},
    appParams: {instance},
    wixCodeApi: {location, window, site},
    config,
  } = controllerConfig;

  return {
    async pageReady() {
      galleryStore = new GalleryStore(
        controllerConfig.setProps,
        httpClient,
        instance,
        bi!.metaSiteId,
        site,
        storage,
        window,
        location,
        settings,
      );
      return galleryStore.setInitialState();
    },
    updateConfig: async (_$w): Promise<void> => {
      galleryStore.onUpdateConfig();
    },
  };
};

export default createController;
