import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {
  QueryDigitalAssetRequest,
  QueryDigitalAssetResponse,
  QueryV2,
} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {queryDigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/http';

export class DigitalAssetsService {
  constructor(private readonly httpClient: IHttpClient, pageSize: number, allCollectionsId: string) {
    this.cursorPaging = {limit: pageSize, cursor: null};
    this.allCollectionsId = allCollectionsId;
  }
  private allCollectionsId: string;
  private sort: QueryDigitalAssetRequest['query']['sort'] = [];
  private filter: QueryDigitalAssetRequest['query']['filter'] = {isHidden: false};
  private cursorPaging: QueryDigitalAssetRequest['query']['cursorPaging'];

  public async fetchAssets(
    request: QueryDigitalAssetRequest = {
      query: {sort: this.sort, cursorPaging: this.cursorPaging, filter: this.filter},
    },
  ): Promise<QueryDigitalAssetResponse> {
    const {data} = await this.httpClient.request(queryDigitalAsset(request));
    return data;
  }

  public async fetchSortedAssets(sort: QueryV2['sort']): Promise<QueryDigitalAssetResponse> {
    this.sort = sort;
    return this.fetchAssets();
  }

  public async fetchPageAssets(cursorPaging: QueryV2['cursorPaging']): Promise<QueryDigitalAssetResponse> {
    return this.fetchAssets({query: {cursorPaging}});
  }

  public async fetchAssetsByCollectionId(collectionId: string): Promise<QueryDigitalAssetResponse> {
    if (collectionId && collectionId !== this.allCollectionsId) {
      this.filter!.collectionId = collectionId;
    } else {
      delete this.filter!.collectionId;
    }
    return this.fetchAssets();
  }
}
