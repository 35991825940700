import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {Collection, QueryCollectionResponse} from '@wix/ambassador-crypto-nft-v1-collection/types';
import {queryUserCollection, getWixCollections} from '@wix/ambassador-crypto-nft-v1-collection/http';
import {
  convertToSortingOption as convertToDropdownOption,
  convertToSortingOptions as convertToDropdownOptions,
} from '../utils';

export class CollectionsService {
  public static allCollectionsId = 'all-collections-id';
  constructor(private readonly httpClient: IHttpClient) {}

  public async fetchUserCollections(): Promise<QueryCollectionResponse['collections']> {
    const {data} = await this.httpClient.request(queryUserCollection({query: {}}));
    return data.collections;
  }

  public async fetchWixCollections(): Promise<QueryCollectionResponse['collections']> {
    const {data} = await this.httpClient.request(getWixCollections({}));
    return data.collections;
  }

  public async getCollectionsFilteringOptions(collections: Collection[]) {
    const collectionsOptions = convertToDropdownOptions(collections);
    const allCollectionsOption = convertToDropdownOption({
      id: CollectionsService.allCollectionsId,
      name: 'Show All Collections',
    });
    collectionsOptions.unshift(allCollectionsOption);
    return collectionsOptions;
  }
}
