import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {DigitalAssetsService} from '../../../../services/digitalAssetsService';
import {Cursors, DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {SortingOptionsIds} from '../../../../types/galleryTypes';
import {SortingService} from '../../../../services/sortingService';
import {ILocation, ISiteApis, IWixWindow, IWebStorage} from '@wix/yoshi-flow-editor';
import {CollectionsService} from '../../../../services/collectionsService';
import {Collection} from '@wix/ambassador-crypto-nft-v1-collection/build/es/types';
import {NavigationService, LastConnectedWalletService} from '@wix/crypto-nft-view-sdk';
import settingsParams from '../../settingsParams';

const appDefinitionId = 'ffaa7766-f73b-4383-8661-c1514a0c1af9';

export class GalleryStore {
  private digitalAssetsService: DigitalAssetsService;
  private collectionsService: CollectionsService;
  private sortingService: SortingService;
  private navigationService: NavigationService;
  private lastConnectedWalletService: LastConnectedWalletService;
  private digitalAssets: DigitalAsset[] | undefined;
  private pageSize: number;
  private selectedCollection: string | undefined;
  private collections: Collection[] = [];
  private isCurrentlyLoading = true;
  private isHasNextPage = false;
  private cursor: Cursors | undefined;

  constructor(
    private readonly updateComponent: (props: {[key: string]: any}) => void,
    private readonly httpClient: IHttpClient,
    private readonly instance: string,
    private readonly metaSiteId: string,
    private readonly siteApi: ISiteApis,
    private readonly storage: {
      local: IWebStorage;
    },
    private readonly window: IWixWindow,
    private readonly location: ILocation,
    private readonly settingsGetter: {get: Function} // TBD - install ISettingsGetter type
  ) {
    this.pageSize = this.calcPageSize();

    this.digitalAssetsService = new DigitalAssetsService(
      this.httpClient,
      this.pageSize,
      CollectionsService.allCollectionsId
    );
    this.collectionsService = new CollectionsService(this.httpClient);
    this.sortingService = new SortingService();
    this.navigationService = new NavigationService(this.storage, this.location, this.siteApi);
    this.lastConnectedWalletService = new LastConnectedWalletService(this.storage);
  }

  private calcPageSize() {
    return this.settingsGetter.get(settingsParams.gridRows) * this.settingsGetter.get(settingsParams.gridColumns);
  }

  public onUpdateConfig() {
    const pageSize = this.calcPageSize();

    if (pageSize !== this.pageSize) {
      this.pageSize = pageSize;
      this.digitalAssetsService = new DigitalAssetsService(
        this.httpClient,
        this.pageSize,
        CollectionsService.allCollectionsId
      );

      this.setInitialState();
    }
  }

  private isSsr(): boolean {
    return this.window.rendering.env === 'backend';
  }

  private async copyToClipboard(text: string) {
    (this.window as any).copyToClipboard && (this.window as any).copyToClipboard(text);
  }

  private async sortAssetsById(sortId: SortingOptionsIds) {
    const {digitalAssets, metadata} = await this.digitalAssetsService.fetchSortedAssets(
      this.sortingService.getSortParameterById(sortId)
    );

    this.digitalAssets = digitalAssets;
    this.isHasNextPage = metadata?.hasNext || false;
    this.cursor = metadata?.cursors;

    this.updateComponent({
      digitalAssets: this.digitalAssets,
      isHasNextPage: this.isHasNextPage,
    });
  }

  private async filterAssetsByCollectionId(collectionId: string) {
    const {digitalAssets, metadata} = await this.digitalAssetsService.fetchAssetsByCollectionId(collectionId);

    this.selectedCollection = collectionId;
    this.digitalAssets = digitalAssets;
    this.isHasNextPage = metadata?.hasNext || false;
    this.cursor = metadata?.cursors;

    this.updateComponent({
      digitalAssets: this.digitalAssets,
      collectionOptionFilteredId: collectionId,
      isHasNextPage: this.isHasNextPage,
    });
  }

  private async getCollectionOptions(): Promise<any> {
    if (this.settingsGetter.get(settingsParams.showWixCollections)) {
      const [userCollections, wixCollection] = await Promise.all([
        this.collectionsService.fetchUserCollections(),
        this.collectionsService.fetchWixCollections(),
      ]);
      this.collections = [...(userCollections || []), ...(wixCollection || [])];
    } else {
      this.collections = (await this.collectionsService.fetchUserCollections()) || [];
    }
    return this.collectionsService.getCollectionsFilteringOptions(this.collections);
  }

  public async setInitialState(): Promise<any> {
    const collectionsOptions = await this.getCollectionOptions();
    const {digitalAssets, metadata} = await this.digitalAssetsService.fetchAssets();
    this.digitalAssets = digitalAssets;
    this.isCurrentlyLoading = false;
    this.isHasNextPage = metadata?.hasNext || false;
    this.cursor = metadata?.cursors;

    const {url} = await this.siteApi.getSectionUrl({sectionId: 'Asset Page', appDefinitionId});
    const lastConnectedWallet = this.lastConnectedWalletService.getLastConnectedWalletKey();

    this.updateComponent({
      collectionsOptions,
      isSsr: this.isSsr(),
      digitalAssets: this.digitalAssets,
      sortingOptions: this.sortingService.getSortOptions(this.settingsGetter),
      isHasNextPage: this.isHasNextPage,
      isCurrentlyLoading: this.isCurrentlyLoading,
      isModalOpened: false,
      instance: this.instance,
      metaSiteId: this.metaSiteId,
      assetPageUrl: url,
      copyToClipboard: this.copyToClipboard.bind(this),
      sortAssetsById: this.sortAssetsById.bind(this),
      filterAssetsByCollectionId: this.filterAssetsByCollectionId.bind(this),
      getNextPage: this.getNextPage.bind(this),
      navigateToAssetPage: this.navigateToAssetPage.bind(this),
      onBuyAssetClicked: this.onBuyAssetClicked.bind(this),
      onCloseModalClicked: this.onCloseModalClicked.bind(this),
      lastConnectedWallet,
      setLastConnectedWalletKey: this.lastConnectedWalletService.setLastConnectedWalletKey.bind(this),
      removeLastConnectedWalletKey: this.lastConnectedWalletService.removeLastConnectedWalletKey.bind(this),
    });
  }

  private async onBuyAssetClicked(assetId: string) {
    const purchasedAsset = this.digitalAssets?.find((asset) => asset.id === assetId);
    const purchasedAssetCollection = this.collections?.find((c) => c.id && c.id === purchasedAsset?.collectionId);
    this.updateComponent({
      purchasedAsset,
      purchasedAssetCollection,
      isModalOpened: true,
    });
  }

  private onCloseModalClicked() {
    this.updateComponent({
      isModalOpened: false,
    });
  }

  private navigateToAssetPage(assetPageUrl: string) {
    this.navigationService.navigateToAssetPage(assetPageUrl, this.digitalAssets);
  }

  public async getNextPage() {
    if (this.isCurrentlyLoading) {
      return;
    }
    this.isCurrentlyLoading = true;
    this.updateComponent({
      isCurrentlyLoading: this.isCurrentlyLoading,
    });
    const cursorPaging = {limit: this.pageSize, cursor: this.cursor?.next};
    const {digitalAssets, metadata} = await this.digitalAssetsService.fetchPageAssets(cursorPaging);
    this.cursor = metadata?.cursors;
    if (this.digitalAssets?.length && digitalAssets?.length) {
      this.digitalAssets.push(...digitalAssets);
      this.isHasNextPage = metadata?.hasNext || false;
    }
    this.isCurrentlyLoading = false;
    this.updateComponent({
      digitalAssets: this.digitalAssets,
      isHasNextPage: this.isHasNextPage,
      isCurrentlyLoading: this.isCurrentlyLoading,
    });
  }
}
